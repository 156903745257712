var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.isErrorPage ? _c('div', {
    staticClass: "app flex-row align-items-center"
  }, [_c('b-jumbotron', {
    staticClass: "bg-transparent",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm._v("Oops, There is Some Error")];
      },
      proxy: true
    }], null, false, 2855500917)
  }, [_c('div', {
    staticClass: "p-3"
  }, [this.message ? _c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(_vm._s(this.message))]) : _c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("Error Not Found, Please Kindly Try Again")])]), _c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "size": "md",
      "to": {
        path: '/'
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward btn-sm btn-dark"
  }), _vm._v(" Return To Home Page ")])], 1)], 1) : _c('div', {
    staticClass: "app flex-row align-items-center"
  }, [_c('div', {
    staticClass: "container mt-3"
  }, [_c('b-row', {
    staticClass: "row justify-content-center"
  }, [_c('b-col', {
    staticClass: "left mt-5 display-mobile",
    attrs: {
      "md": "6",
      "sm": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "height": "75%"
    },
    attrs: {
      "src": require("../../assets/img/design.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('b-col', {
    staticClass: "right",
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-group', [_c('b-card-body', {
    staticClass: "background-mobile"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "m-5",
    attrs: {
      "src": require("../../assets/img/gramedia_digital.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })])], 1), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.actionLogin.apply(null, arguments);
      }
    }
  }, [_c('h1', {
    staticClass: "h2 text-center"
  }, [_vm._v(" Digital Books Gramedia ")]), _c('h3', {
    staticClass: "h2 text-center"
  }, [_vm._v(" Two Factor Authentication ")]), this.isKey ? _c('div', [this.message ? _c('div', [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(this.message))]), _c('b-form-group', {
    staticClass: "mt-5 mb-0",
    attrs: {
      "label": "OTP : "
    }
  }, [_c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "OTP",
      "autocomplete": "OTP",
      "required": ""
    },
    model: {
      value: _vm.Form.token,
      callback: function ($$v) {
        _vm.$set(_vm.Form, "token", $$v);
      },
      expression: "Form.token"
    }
  })], 1)], 1) : _c('div', [_c('h1', {
    staticClass: "h2 text-center"
  }, [_vm._v("Please Input Code From Google Authenticator ")]), _c('b-form-group', {
    staticClass: "mt-5 mb-0",
    attrs: {
      "label": "OTP : "
    }
  }, [_c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "OTP",
      "autocomplete": "OTP",
      "required": ""
    }
  })], 1)], 1), _c('div', {
    staticClass: "my-3"
  }, [_c('b-col', [_c('b-row', [_c('b-button', {
    staticClass: "px-4 w-100",
    attrs: {
      "disabled": _vm.isDisable,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Sign In "), _c('i', {
    staticClass: "fa fa-sign-in",
    attrs: {
      "aria-hidden": "true"
    }
  }), !_vm.isDisable && _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)]) : _c('div', [this.message ? _c('div', [_c('h5', {
    staticClass: "text-center my-3"
  }, [_vm._v(_vm._s(this.message))])]) : _c('div', [_c('h1', {
    staticClass: "h2 text-center"
  }, [_vm._v("Please Authorized Your Device")])]), _c('b-button', {
    staticClass: "px-4 w-100 my-3",
    attrs: {
      "lg": "4",
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": _vm.createNewAuth
    }
  }, [_vm._v(" Authorized New Device "), _c('i', {
    staticClass: "fa fa-sign-in",
    attrs: {
      "aria-hidden": "true"
    }
  })])], 1)])], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }