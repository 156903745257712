<template>
  <div class="app flex-row align-items-center"  v-if='this.isErrorPage' >
    <b-jumbotron class='bg-transparent'>
      <template #header>Oops, There is Some Error</template>
      <div class='p-3'>
        <h5 class='card-title mb-0 font-weight-bold' v-if='this.message'>{{this.message}}</h5>
        <h5 class='card-title mb-0 font-weight-bold' v-else>Error Not Found, Please Kindly Try Again</h5>
      </div>
    
      <router-link class="btn btn-sm btn-dark  "  size="md"  :to="{ path: '/'}">
        <em class="fa fa-backward btn-sm btn-dark "></em> Return To Home Page
      </router-link>
    </b-jumbotron>
  </div>
  <div class="app flex-row align-items-center" v-else>
    <div class="container mt-3">
      <b-row class="row justify-content-center">
        <b-col md="6" sm="0" class="left mt-5 display-mobile">
          <img style="height: 75%"
            src="../../assets/img/design.png"
            alt="admin@bootstrapmaster.com"
          />
        </b-col>
        <b-col md="6" class="right">
          <b-card-group>
              <b-card-body class="background-mobile">
                <b-row align-v="center">
                  <b-col class="text-center">
                  <img
                    src="../../assets/img/gramedia_digital.png"
                    alt="admin@bootstrapmaster.com"
                    class="m-5"
                  />
                  </b-col>
                </b-row>
                <b-form @submit.prevent="actionLogin">
                  <h1 class="h2 text-center">  Digital Books Gramedia </h1>
                  <h3 class="h2 text-center">  Two Factor Authentication </h3>
                <div v-if='this.isKey' >
                  <div v-if='this.message' >
                    <h5 class="text-center">{{this.message}}</h5>
                    <b-form-group label="OTP : " class="mt-5 mb-0">
                      <b-form-input
                        class="form-control"
                        placeholder="OTP"
                        autocomplete="OTP"
                        v-model="Form.token"
                        required
                      />
                    </b-form-group>
                  </div>
                  <div v-else>
                    <h1 class="h2 text-center">Please Input Code From Google Authenticator </h1>
                    <b-form-group label="OTP : " class="mt-5 mb-0">
                      <b-form-input
                        class="form-control"
                        placeholder="OTP"
                        autocomplete="OTP"
                        required
                      />
                    </b-form-group>
                  </div>
                  <div class="my-3">
                  <b-col>
                    <b-row>
                      <b-button
                        :disabled="isDisable"
                        type="submit"
                        variant="primary"
                        class="px-4 w-100"
                      >
                        Sign In
                       <i class="fa fa-sign-in" aria-hidden="true"></i>
                        <b-spinner v-if="!isDisable && isLoading" type="grow" label="Spinning"></b-spinner>
                      </b-button>
                    </b-row>
                  </b-col>
                </div>
                </div>
                <div v-else>
                  <div v-if='this.message' >
                    <h5 class="text-center my-3">{{this.message}}</h5>
                  </div>
                  <div v-else>
                    <h1 class="h2 text-center">Please Authorized Your Device</h1>
                  </div>
                  <b-button
                      lg="4"
                      type="submit"
                      variant="primary"
                      class="px-4 w-100 my-3"
                      @click="createNewAuth"
                    >
                    Authorized New Device
                    <i class="fa fa-sign-in" aria-hidden="true"></i>
                    
                  </b-button>
                  
                </div>
                </b-form>
              </b-card-body>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "twoFactor",
  data() {
    return {
      Form: {},
      isActive: false,
    };
  },created(){
    let token=this.$route.params.token;
    this.token = this.$route.params.token;
    this.validateJWT({token});
  },
  computed: {
    ...mapState({
      success: (state) => state.login.isSuccess,
      message: (state) => state.login.successMessage,
      isErrorPage:(state)=> state.login.isErrorPage,
      isLoading: (state) => state.login.isLoading,
      isError: (state) =>state.login.isError,
      page: (state) => state.login.page,
      isKey : (state) => state.login.isKey,
      key : (state) => state.login.key,
    }),isDisable() {
      return !(this.Form.token != null)
    }
  },
  watch: {
    $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Two Factor Authentication | Content Management System Ebooks Gramedia.com';
        }
    },
    isError: function(){
      if(this.isError){
        if(this.message){
          this.showAlert({
          success: false,
          message: "Error Verification, "+this.message,
          title: "Error Login",
          });
        }
      }
    },
    success: function (){
      if(this.success){
          this.showAlert({
          success: true,
          message: this.message,
          title: "Success Authenticate",
          });
        }
      }
    },
  methods: {
    ...mapActions("login", ["validateJWT","verify2Factor"]),
    ...mapActions("auth",["setLogin","setAuth"]),
    actionLogin() {
      let payload = {
        token: this.token,
        tokenNumber: this.Form.token,
      };
      this.verify2Factor(payload).then((response) => {
        const result = response.data.data[0].result;
        let message = response.data.data[0].message;
        if (!message) {
          message = 'Error Authentication Login';
        }
        if (result) {
          const arrayToken = response.data.data[0].data;
          let user = arrayToken.data
          localStorage.setItem("session_name", arrayToken.data.username);
          localStorage.setItem("session_token", arrayToken.tokenLogin);
          localStorage.setItem("refresh_token", arrayToken.refresh_token);
          localStorage.setItem("session_auth",JSON.stringify(user))
          this.setAuth(user)
          this.setLogin({isLogin: true})
          this.showAlert({
            success: true,
            message: message,
          });
        } else {
          this.showAlert({
            success: false,
            message: message,
            title: "Error",
          });
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          let message = error.data.data[0].message;
          if (!error) {
            message = error;
          }
          if (!message) {
            message = 'Error Authentication Login';
          }
          this.showAlert({
            success: false,
            message: "Login Failed verification: "+error,
            title: "Error",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      },
    showAlert(data) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon: data.success ? "success" : "error",
        title: data.message,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },createNewAuth(e) {
      e.preventDefault();
          this.$router.push({
          path: `/newAuth/${this.token}`,
        });
    },
  },
  mounted() {
    let token = localStorage.getItem("session_token");
    if (token) {
      this.$router.push({ path: "/" });
    }
  },
};
</script>
<style lang="css" scoped>
.center {
  width: 500px;
  height: 400px;
  top: 20%;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
.form-control {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
}
input.form-control:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
@media (min-width: 360px) and (max-width: 768px) {
  .background-mobile {
    background-color: #fff;
  }
  .display-mobile {
    display: none;
  }
}

</style>
